<template>
  <div class="form-data">
    <b-form @submit.prevent="handleSubmitCredentials()">
      <b-form-group :label="$t('general.name')">
        <b-form-input
          v-model="$v.form.name.$model"
          :class="$v.form.name.$error && 'is-invalid'"
          trim
        >
          <b-form-invalid-feedback v-if="!$v.form.name.required">
            {{ $t("auth.type-valid-name") }}
          </b-form-invalid-feedback>
        </b-form-input>
      </b-form-group>
      <b-form-group :label="$t('general.social_reason')">
        <b-form-input
          v-model.trim="$v.form.social_reason.$model"
          :class="$v.form.social_reason.$error && 'is-invalid'"
        >
          <b-form-invalid-feedback v-if="!$v.form.social_reason.required">
            {{ $t("auth.type-valid-name") }}
          </b-form-invalid-feedback>
        </b-form-input>
      </b-form-group>
      <b-form-group :label="$t('general.email')">
        <b-form-input
          placeholder="email@email.com"
          v-model="$v.form.email.$model"
          :class="$v.form.email.$error && 'is-invalid'"
          trim
        ></b-form-input>
        <b-form-invalid-feedback
          v-if="!$v.form.email.email || !$v.form.email.required"
        >
          {{ $t("auth.type-valid-email") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex justify-content-end mt-4">
        <b-button variant="link" @click="$emit('submited')">Cancelar</b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="isLoading"
          class="pull-right text-right"
        >
          <b-spinner v-if="isLoading" small class="mr-2" />
          <span> {{ $t("general.save") }}</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  mixins: [validationMixin],
  props: {
    id: {
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: null,
        social_reason: null,
        email: null,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4),
      },
      social_reason: {
        required,
        minLength: minLength(4),
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions(["invite_provider", "get_provider", "show_toast"]),
    handleSubmitCredentials() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError && !this.$v.$anyError) {
        this.isLoading = true;
        this.invite_provider({ ...this.form })
          .then(({ status }) => {
            if ([200, 201].includes(status)) {
              this.show_toast({
                message: "Cadastro realizado com sucesso",
                type: "success",
              });
              this.$emit("submited");
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.show_toast({
              message: err,
              type: "error",
            });
          });
      }
    },
    getEdit() {
      this.get_provider({ id: this.id }).then((data) => {
        this.form = data;
      });
    },
  },
  mounted() {
    if (this.id) {
      this.getEdit();
    }
  },
};
</script>

<style scoped lang="scss">
.form-data {
  max-width: 600px;
  margin: 0 auto;
}
</style>
