<template>
  <div>
    <div class="my-2 mb-4" v-if="!isBusy">
      <b-button
        small
        size="sm"
        :variant="`${status === 1 ? 'primary' : 'light'}`"
        @click="handleSetStatus(1)"
        class="mr-1"
      >
        Enviados
      </b-button>
      <b-button
        small
        size="sm"
        :variant="`${status === 2 ? 'primary' : 'light'}`"
        @click="handleSetStatus(2)"
        class="mr-1"
      >
        Aceitos
      </b-button>
    </div>
    <div class="d-flex justify-content-between">
      <div class="input-search mb-4">
        <b-form-input
          @input="handleSearch"
          v-model="search"
          placeholder="Digite para pesquisar"
          class="col-3 mb-2"
        />
        <b-button
          variant="light"
          class="px-2 d-flex align-items-center absolute icon-action"
        >
          <b-icon icon="search" />
        </b-button>
      </div>
      <b-button
        variant="secondary"
        @click="handleAddNewUser()"
        class="btn-add"
      >
        Adicionar
      </b-button>
    </div>
    <empty-list v-if="items.length === 0" :size="70">
    </empty-list>
    <div v-else>
      <div class="min-h-55">
        <cards-list grid="4" gap="10">
          <b-card v-for="(item, index) in items" :key="index" class="py-1">
            <section class="d-flex w-100">
              <section class="w-100">
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                  v-if="status === 1"
                >
                  <strong
                    class="d-block col-8 p-0"
                    :class="item.is_expired ? 'text-danger' : 'text-success'"
                  >
                    {{
                      item.is_expired ? "Convite expirado" : "Convite válido"
                    }}
                  </strong>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                  v-else
                >
                  <strong class="d-block col-8 p-0 text-primary">
                    Convite aceito
                  </strong>
                </div>
                <small class="mb-3 d-block">
                  <strong>E-mail: </strong> <br />
                  {{ item.email }}
                </small>
                <small class="d-block">
                  <strong>Convidado em: </strong> <br />
                  {{ moment(item.updated_at).format("DD/MM/YYYY HH:mm") }}
                </small>
              </section>
            </section>
            <div class="actions" v-if="status === 1">
              <a
                v-disable="resendId === item.id"
                title="Reenviar"
                class="
                  text-center
                  pointer
                  d-flex
                  align-items-center
                  line-height-1
                  mt-3
                  btn-outline-primary
                  btn-sm
                  d-table
                  btn
                "
                @click="handleResendInvite(item)"
              >
                <b-spinner
                  class="h6 mr-1 mb-0"
                  v-if="resendId === item.id"
                  small
                  type="grow"
                />

                <span>Reenviar</span>
              </a>
            </div>
          </b-card>
        </cards-list>
      </div>
      <b-pagination
        @change="handleChangePage"
        v-model="current_page"
        :total-rows="total"
        :per-page="per_page"
        aria-controls="my-table"
      />
    </div>
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapActions } from "vuex";

export default {
  mixins: [listPaginated],

  props: {
    params: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      provider: null,
      resendId: null,
      selectedId: null,
      statusInvite: [
        { id: 1, name: "Pendente" },
        { id: 2, name: "Aceito" },
        { id: 3, name: "Recusado" },
        { id: 4, name: "Cancelado" },
      ],
      status: 1,
    };
  },
  methods: {
    ...mapActions(["resend_invite", "show_toast"]),
    handleAddNewUser() {
      this.isAdd = true;
      this.$bvModal.show("add-provider");
    },
    handleEditUser(id) {
      this.isAdd = false;
      this.selectedId = id;
      this.$bvModal.show("add-provider");
    },
    handleCloseModal() {
      this.$root.$emit("update_list");
      this.$bvModal.hide("add-provider");
    },
    handleSelectprovider(provider) {
      this.$emit("open_drawer", { item: { ...provider } });
      this.provider = provider.item;
    },
    handleSetStatus(status) {
      this.current_page = 1;
      this.status = status;
      this.$emit("update_status", status);
    },
    handleResendInvite(item) {
      this.resendId = item?.id;
      this.loading = true;
      this.resend_invite(this.resendId).then(() => {
        this.resendId = null;
        this.loading = false;
        this.show_toast({
          message: "Convite reenviado com sucesso",
          type: "success",
        });
        this.getList();
      });
    },
  },
};
</script>
