<template>
  <div>
    <div class="input-search mb-4 col-md-4 p-0">
      <b-form-input
        @input="handleSearch"
        v-model="search"
        placeholder="Digite para pesquisar"
        class="col-3 mb-2"
      />
      <b-button
        variant="light"
        class="px-2 d-flex align-items-center absolute icon-action"
      >
        <b-icon icon="search"></b-icon>
      </b-button>
    </div>
    <template>
      <skeleton height="198px" rows="8" grid="4" gap="8" v-if="isBusy" />
      <empty-list v-if="items && items.length === 0 && !isBusy" />
      <div class="min-h-55" v-else>
        <cards-list grid="4" gap="10">
          <b-card
            v-for="(item, index) in items"
            :key="index"
            class="card-hoverable pointer"
          >
            <router-link :to="`/provider/edit/${item.id}`" class="text-muted">
              <section class="d-md-block">
                <div
                  class="
                      d-flex
                      align-items-center
                      justify-content-start
                      gap-2
                    "
                >
                  <avatar :name="item.fantasy_name" :image="item.photo" />
                  <strong class="d-block" v-if="item.fantasy_name">
                    {{ item.fantasy_name }}
                  </strong>
                  <i class="d-block" v-else> Cadastro incompleto </i>
                </div>
                <section>
                  <small class="my-3 d-block">
                    <strong>Razão social:</strong> <br />
                    {{ item.social_reason }}
                  </small>
                  <small class="mb-3">
                    <strong>E-mail:</strong> <br />
                    {{ item.email || "Não preenchido" }}
                  </small>
                  <small v-if="item && item.enterprise" class="d-block">
                    <strong>Empresa:</strong>
                    {{ item.enterprise.social_reason }}
                  </small>
                </section>
              </section>
            </router-link>
          </b-card>
        </cards-list>
      </div>
    </template>

    <b-pagination
      @change="handleChangePage"
      v-model="current_page"
      :total-rows="total"
      :per-page="per_page"
      aria-controls="my-table"
    />
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [listPaginated],
  data() {
    return {
      paginate: true,
      selectedUser: {
        id: null,
      },
      provider: null,
      menus: [
        {
          name: "Cadastrados",
          id: "register",
        },
        { name: "Listagem de convites", id: "invites" },
      ],
      statusInvite: [
        { id: 1, name: "Pendente" },
        { id: 2, name: "Aceito" },
        { id: 3, name: "Recusado" },
        { id: 4, name: "Cancelado" },
      ],
      status: 1,
    };
  },
  computed: {
    ...mapGetters([
      "is_provider",
      "is_mobile",
      "preview_personify",
      "is_preview",
    ]),
  },

  methods: {
    ...mapActions(["show_toast", "delete_provider", "resend_invite"]),
    handleAddNewUser() {
      this.isAdd = true;
      this.selectedId = this.selectedUser.id;
      this.$bvModal.show("add-user");
    },
    handleEditUser(id) {
      this.isAdd = false;
      this.selectedId = id;
      this.$bvModal.show("add-user");
    },
    handleCloseModal() {
      this.$root.$emit("update_list");
      this.$bvModal.hide("add-user");
    },
    handleSelectMenu(user) {
      this.selectedUser = user;
    },
    handleSelectprovider(provider) {
      this.$emit("open_drawer", { item: { ...provider } });
      this.provider = provider.item;
    },
    handleSetStatus(status) {
      this.status = status;
    },
  },
};
</script>
