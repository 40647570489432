<template>
  <div>
    <div>
      <b-tabs pills small nav-class="nav-users">
        <b-tab
          @click="handleSelectMenu(menu)"
          :variant="selectedMenu === menu ? 'primary' : 'light'"
          v-for="(menu, index) in menus"
          :key="index"
          :title="menu.name"
        >
        </b-tab>
      </b-tabs>
      <div>
        <list-registred
          v-if="selectedMenu.id === 'register'"
          :params="{ progress }"
          action="provider"
          button_name="Convidar"
          enable_event_edit
          base_view="/provider"
          @open_drawer="handleSelectprovider"
        />
        <!-- 
        Dictionary params:
         status = status invite
         uy = user type
         type= type invite
         -->
        <list-invite
          v-if="selectedMenu.id === 'invites'"
          button_name="Convidar"
          @add_row="handleAddNewClient"
          :params="{ type: 'i', status, ut: 'p' }"
          action="notifications"
          base_view="/notifications"
          @update_status="handleSetStatus"
        />
      </div>
    </div>
    <b-modal
      id="add-provider"
      centered
      hide-footer
      size="md"
      no-close-on-backdrop
      :title="`${selectedId ? 'Editar convite' : 'Convidar prestador'} `"
    >
      <Add :id="selectedId" @submited="handleCloseModal" />
    </b-modal>
    <b-sidebar
      id="sidebar-list-responsible"
      backdrop-variant="light"
      backdrop
      right
      shadow
      align="right"
      width="400px"
    >
      <template #default="{ hide }">
        <div class="p-3 text-left">
          <div class="mb-2">
            <h5 class="mb-0">{{ provider.social_reason }}</h5>
            <small class="text-muted">
              <b-icon icon="building" />
              {{ provider.segment || "Sem segmento" }}
            </small>
          </div>
          <div v-if="!provider.responsibles" class="text-center">
            <img src="@/assets/images/empty-box.png" alt="" height="40" />
            <p>Não há responsáveis cadastrados</p>
          </div>
          <div class="mb-2" v-if="provider.responsibles">
            <hr />
            <span class="mb-2 d-block">Responsáveis legais</span>
            <b-card
              v-for="(pvdr, index) in provider.responsibles"
              :key="index"
              class="mb-2"
            >
              <div class="d-block">
                <strong class="mr-1">
                  <b-icon icon="bookmark-star-fill" variant="warning" />
                  {{ pvdr.name }}
                </strong>
                <small class="text-muted"> Responsável principal </small>
              </div>
              <span> <b-icon icon="envelope" /> {{ pvdr.email }}</span>
            </b-card>
          </div>
          <router-link
            :to="`/legal-responsible/list/?enterprise_id=${provider.id}`"
          >
            <b-button variant="primary" block @click="hide">
              Gerenciar esta lista
            </b-button>
          </router-link>
        </div>
      </template>
    </b-sidebar>

    <b-modal id="delete-provider" centered size="sm">
      <template #modal-header> <h5>Cuidado!</h5></template>
      <p>
        Você apagará o prestador
        <strong> {{ selected.social_reason }} </strong>. <br />
        Essa ação não poderá ser desfeita, deseja continuar?
      </p>
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="handleDeleteRow()">Sim</b-button>
        <b-button variant="primary" @click="close()">Não</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ListRegistred from "./list.registred.vue";
import ListInvite from "./list.invite.vue";
import Add from "./add";
import _ from "lodash";

export default {
  components: {
    Add,
    ListRegistred,
    ListInvite,
  },
  data() {
    return {
      isAdd: false,
      userType: "",
      selectedMenu: {
        id: null,
      },
      menus: [
        {
          name: "Cadastrados",
          id: "register",
        },
        { name: "Listagem de convites", id: "invites" },
      ],
      statusInvite: [
        { id: 1, name: "Pendente" },
        { id: 2, name: "Aceito" },
        { id: 3, name: "Recusado" },
        { id: 4, name: "Cancelado" },
      ],
      selected: {},
      provider: [],
      selectedId: null,
      resendId: null,
      status: 1,
      percentage: 100,
      progress: 100,
    };
  },
  methods: {
    ...mapActions(["show_toast", "delete_provider", "resend_invite", "user"]),
    handleAddNewClient(id) {
      this.selectedId = id || null;
      this.$bvModal.show("add-provider");
    },
    handleCloseModal() {
      this.$root.$emit("update_list");
      this.$bvModal.hide("add-provider");
    },
    handleSetStatus(status) {
      this.status = status;
    },
    handleChangePercentage: _.debounce(function(value) {
      this.progress = value;
    }, 500),
    handleSelectprovider(provider) {
      this.provider = provider.item;
    },
    handleConfirmDelete(item) {
      this.selected = item;
      this.$bvModal.show("delete-provider");
    },
    handleDeleteRow() {
      this.delete_provider(this.selected.id).then(() => {
        this.selected = {};
        this.$refs.provider_table.getList();
      });
      this.$bvModal.hide("delete-provider");
    },
    handleResendInvite({ item: { id } }) {
      this.resendId = id;
      this.resend_invite(id).then(() => {
        this.resendId = null;
        this.show_toast({
          message: "Convite reenviado com sucesso",
          type: "success",
        });
        this.$refs.provider_invite.getList();
      });
    },
    handleSelectMenu(user) {
      this.selectedMenu = user;
    },
  },
  computed: {
    ...mapGetters([
      "is_provider",
      "is_mobile",
      "preview_personify",
      "is_preview",
    ]),
  },
  mounted() {
    this.selectedMenu = this.menus[0];
  },
};
</script>

<style lang="scss">
.min-200 {
  min-width: 100px;
}
</style>
